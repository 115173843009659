import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El nom llatí de la gírgola d’alzina fa referència al color mel d’aquest bolet, més obscur al centre del capell. Aquest és carnós, globós al principi i es va obrint poc a poc. Té un diàmetre de 4-12 cm i la superfície està coberta de petites punxes esquamoses més nombroses al centre. Les làmines són desiguals, poc amples i no gaire atapeïdes de color blanc a blanc grogós. El peu, de 10 a 18 cm presenta forma cilíndrica aixemplada un poc a la base on pot mesurar 1,5-3 cm de gruixa. És de color pàl·lid a la part superior, terrós a la part central i olivaci a la part inferior. L’anell és molt evident, membranós, persistent, blanc i amb esquames groguenques. Les espores són blanques en massa, molt nombroses, el·lipsoïdals, de 7-9 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      